


body {
    scroll-behavior: auto;
    background: #ffffff;
    overflow-x: hidden;
}
/*------------------------
    Header Sticky 
--------------------------*/

// custom column

.col-lg-20{
    width: 20%;
    float: left;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.col-lg-20{
    @media (min-width: 1200px) {
        width: 20%;
        float: left;
    }
    @media #{$smlg-device} {
        width: 33%;
        float: left;
    }
    @media #{$md-layout} {
        width: 50%;
        float: left;
    }
    @media #{$sm-layout} {
        width: 50%;
        float: left;
    }
    @media #{$large-mobile} {
        width: 50%;
        float: left;
    }
    @media #{$small-mobile} {
        width: 100%;
        float: left;
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5D666F !important;
    opacity: 1 !important; /* Firefox */
    font-size: 16px;
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5D666F  !important;
    font-size: 16px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #5D666F !important;
    font-size: 16px;
  }


.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}



// title pre-title

.over_link{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
}



.home-one{
    background: #F2F3F5;
}

// title-area style

.elecate-center-title{
    text-align: center;
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #4EB17D;
        text-transform: uppercase;
        font-family: var(--font-primary);
    }
    h3.title{
        font-weight: 700;
        font-size: 40px;
        line-height: 61px;
        color: #0C0A0A;
        text-transform: uppercase;
        margin-top: 5px;
        @media #{$sm-layout} {
            font-size: 30px;
            line-height: 42px;
            br{
                display: none;
            }
        }
    }
}

.elecate-left-title{
    text-align: left;
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #4EB17D;
        text-transform: uppercase;
        font-family: var(--font-primary);
    }
    h3.title{
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #0C0A0A;
        text-transform: uppercase;
        margin-top: 5px;
        // @media screen and (max-width: 1919px) {
        //     font-size: 20px;
        //     line-height: 33px;
        // }
        @media #{$laptop-device} {
            font-size: 28px;
            line-height: 43px;
        }
        @media #{$sm-layout} {
            font-size: 29px;
            line-height: 41px;        
        }
    }
}



.home-five{
    .title-mid-wrapper-home-two{
        text-align: center;
        span{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: var(--color-primary) !important;
            text-transform: uppercase;
        }
        .title{
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            color: #0C0A0A;
            @media #{$sm-layout} {
                font-size: 34px;
                 line-height: 42px;
            }
            @media #{$large-mobile} {
                font-size: 26px;
                line-height: 33px;
            }
            br{
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }
    }
    .title-left-wrapper-home-two{
        text-align: left;
        span{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: var(--color-primary) !important;
            text-transform: uppercase;
            font-family: var(--font-primary);
        }
        .title{
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            color: #0C0A0A;
            @media #{$laptop-device} {
                font-size: 30px;
                line-height: 40px;
            }
            @media #{$smlg-device} {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }
    .main-wrapper-testimonials {
        border: 1px solid #A7A7A7;
    }
}

body{
    &.index-nine{
        background: #F2F2F2;
        .main-title-area-center{
            text-align: center;
            span.pre{
                color: var(--color-primary);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
            }
        }
    }
}




